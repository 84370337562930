<template>
  <section
      :id="!!id.length ? id : null"
      class="homepage-hero push-v--desktop--x-small bg--white"
  >
    <div class="left-bg bg--new-green">
      <div ref="textBox" class="content__wrapper">
        <div class="content__parent">
          <div class="homepage-hero__title">
            <span class="visually-hidden">{{ title }}</span>
            <p class="homepage-hero__slogan text-right--desktop">{{ slogan }}</p>
            <h1 class="homepage-hero__title--main">
              {{ title }}
            </h1>
            <p class="homepage-hero__subtitle">{{ subtitle }}</p>
          </div>
        </div>
        <div class="homepage-hero__buttons--wrapper">
          <button v-if="ctaLongStayLabel" class="homepage-hero__button" @click="clickCTALongStay">{{ ctaLongStayLabel }}</button>
          <!-- <button v-if="ctaShortStayLabel" class="homepage-hero__button" @click="clickCTAShortStay">{{ ctaShortStayLabel }}</button> -->
        </div>
      </div>
      
      <div ref="arrowBox" class="left-bg--arrow-parent" @click="scrollBelow"><ArrowIcon class="left-bg--arrow-icon" /></div>
    </div>
    <div class="right-bg bg--white">
      <div id="homepage-hero-image" ref="heroImage" class="right-bg__image">
        <KenticoResponsiveImage :src="image.url" :alt="image.description" class="homepage-hero__image" :sizes="imageSizes" :onload="onImageLoad" />
      </div>
    </div>
  </section>
</template>

<script>
import { BREAKPOINT_MD, BREAKPOINTS, TABNAME_LONG_STAY, TABNAME_SHORT_STAY } from '~/utils/constants';
import { forceObject } from '~/utils/processor';
import getStatic from '~/utils/get-static';
import { trackClickedCta } from '~/utils/gtm-events.js';
import ArrowIcon from '~/assets/icons/new/arrow-down.svg?inline';
import { gsap, TimelineLite, CSSPlugin, Power1 as Sine } from 'gsap';


export default {
  components: { 
    ArrowIcon,
  },
  props: {
    id: { type: String, default: '' },
    image: {
      type: Object,
      default() {
        return {
          url: getStatic('/images/content/homepage/hero.jpg'),
          description: 'Alt text',
        };
      },
    },
    title: { type: String, default: 'Discover a better<wbr> way of living.' },
    slogan: { type: String, default: 'Be more together' },
    subtitle: { type: String, default: 'Enjoy one-of-a-kind shared spaces, community events and your own private studio apartment, all in one hassle-free bill.' },
    anchorLink: { type: String, default: 'locations' },
    anchorLinkText: { type: String, default: '' },
    callToActionLinkText: { type: String, default: '' },
    ctaLongStayLabel: { type: String, default: 'Stay for months' },
    ctaShortStayLabel: { type: String, default: 'Book for a few nights' },
    callToActionLink: {
      type: Object,
      default() {
        return {
          slug: '/',
        };
      },
    },
    body: {
      type: String,
      default:
        '<p>Combining private ensuites with beautiful shared spaces and a programme of inspiring events, all included in one monthly bill, we help our members get the most out of city living.</p>',
    },
    backgroundColour: {
      type: Array,
      default() {
        return [
          {
            name: 'New Green',
          },
        ];
      },
    },
    leafOneColour: {
      type: Array,
      default() {
        return [
          {
            name: 'White',
          },
        ];
      },
    },
    leafTwoColour: {
      type: Array,
      default() {
        return [
          {
            name: 'Pale Orange',
          },
        ];
      },
    },
  },
  data() {
    return {
      imageSizes: {
        default: [900, 750],
        sizes: [
          { breakpoint: BREAKPOINTS[BREAKPOINT_MD], width: 1200, height: 1000 },
        ],

      },
      lonStayTabName: TABNAME_LONG_STAY,
      shortStayTabName: TABNAME_SHORT_STAY,
    };
  },
  computed: {
    backgroundColourObj() {
      return forceObject(this.backgroundColour);
    },
    leafOneColourObj() {
      return forceObject(this.leafOneColour);
    },
    leafTwoColourObj() {
      return forceObject(this.leafTwoColour);
    },
    callToActionLinkSlug() {
      if (this.callToActionLink && typeof this.callToActionLink.slug !== 'undefined') {
        return this.callToActionLink.slug;
      }

      return null;
    },
  },
  mounted() {
    
    setTimeout(this.animate, 100);
   
     
  },

  methods: {
    clickCTAShortStay() {
      const eventData = {
        category: 'SelectStayType',
        action: 'SelectShortStay',
        label: 'BookForAFewNights',
        property: 'HomePage',
        value: null,
        event: 'clickedCta',
      };
      trackClickedCta(this.$gtm, eventData);
      this.$modal.show('booking', { location: null, bookingType: this.shortStayTabName });
    },
    clickCTALongStay() {
      const eventData = {
        category: 'SelectStayType',
        action: 'SelectLongStay',
        label: 'StayForMonths',
        property: 'HomePage',
        value: null,
        event: 'clickedCta',
      };
      trackClickedCta(this.$gtm, eventData);
      this.$modal.show('booking', { location: null, bookingType: this.lonStayTabName });
    },
    stringToClass(string) {
      return string.toLowerCase().replace(' ', '-');
    },
    openBookingPanel(bookingType) {

    },    
    scrollBelow() {
      const ele = document.querySelector('.co-living-highlights');
      ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    onImageLoad(event) {
      const { heroImage } = this.$refs;
      const tl = new TimelineLite();
      tl
          .fromTo(heroImage, { autoAlpha: 0.3, x: 150 }, { autoAlpha: 1, x: 0, duration: 1, ease: Sine.easeOut });
    },
    animate() {
      const { textBox, arrowBox, heroImage } = this.$refs;
      gsap.registerPlugin(CSSPlugin);

      const tl = new TimelineLite();
      tl
          .fromTo(heroImage, { autoAlpha: 0.3, x: 150 }, { autoAlpha: 1, x: 0, duration: 1, ease: Sine.easeOut })
          .fromTo(textBox, { autoAlpha: 0, y: 150 }, { autoAlpha: 1, y: 0, duration: 0.7, ease: Sine.easeOut }, '-=0.4')
          .fromTo(arrowBox, { y: -100, autoAlpha: 0.8 }, { y: 0, autoAlpha: 1, repeat: 4, yoyo: true, duration: 1, ease: Sine.easeIn }, '-=0.4');
    
    },
    colourClass(colorObj) {
      if (colorObj && colorObj.name) {
        const { name } = colorObj;
        return `bg--${this.stringToClass(name)}`;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_homepage-hero.scss';
</style>
